import React, { FC, HTMLAttributes } from 'react';
import Block from '../block/block';
import './block-compartment.scss';

const BlockCompartment: FC<HTMLAttributes<HTMLElement>> = ({ children, className, ...props }) => {

    return (
        <div className="wrapper">
            <a href="/contact">
                <Block>
                    <h3>Marktkramen</h3>
                    <p>Heeft u er 1, 10 of 100 nodig? Dat maakt niet uit neem contact op dan overleggen we over de
                        opties.</p>
                </Block>
            </a>
            <a href="/contact">
                <Block>
                    <h3>Toiletwagens</h3>
                    <p>Heeft u een nodig voor een feest of iets anders? Ook deze verhuur ik neem contact met me op voor
                        meer informatie.</p>
                </Block>
            </a>
            <a href="/contact">
                <Block>
                    <h3>Plaskruisen</h3>
                    <p>Naast de prachtige toiletwagens is er ook een optie voor een eenvoudiger product. Deze verhuur ik
                        nu ook.</p>
                </Block>
            </a>
        </div>
    );
};

export default BlockCompartment;

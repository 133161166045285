import React, { FC, HTMLAttributes } from 'react';
import './home.scss';
import BlockCompartment from '../../components/block-compartment/block-compartment';

const Home: FC<HTMLAttributes<HTMLElement>> = ({ children, className, ...props }) => {

    return (
        <div className="home">
            <h2>Verhuur van marktkramen, toiletwagens en plaskruisen</h2>
            <p className="intro">Heijdra Marktkramen verhuurt naast marktkramen ook toiletwagens en plaskruisen. <br/>
                U kunt contact met mij opnemen via:
                <a href="tel:0629303627">06-29303627</a> of
                <a href="mailto:zeno@heijdramarktkramen.nl">zeno@heijdramarktkramen.nl</a>
            </p>

            <BlockCompartment/>
        </div>
    );
};

export default Home;

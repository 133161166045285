import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import './App.scss';

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/contact",
            element: <Contact />,
        },
        {
            path: "/*",
            element: <Home />,
        },
    ]);

    return (
        <div className="app">
            <header>
                <a href="./"><img src="../images/logo_zeno.png" alt="home"/></a>
                <h1>Heijdra Marktkramen</h1>
                <a href="/contact">
                    <p>Contact</p>
                </a>
            </header>
            <div className="content">
                <RouterProvider router={router} />
            </div>
            <footer>
                <p>Heijdra Marktkramen {new Date().getFullYear()}</p>
            </footer>
        </div>
    );
}

export default App;

import React, { FC, HTMLAttributes } from 'react';
import './contact.scss';

const Contact: FC<HTMLAttributes<HTMLElement>> = ({ children, className, ...props }) => {

    return (
        <div className="contact">
            <p className="intro">
                Wilt u iets reserveren, een vrijblijvende offerte of meer informatie over een van de producten? </p>
            <p className="contact">Neem dan contact met mij op via:</p>
            <p className="links">
                <a href="tel:0629303627">06-29303627</a> of <a href="mailto:zeno@heijdramarktkramen.nl">zeno@heijdramarktkramen.nl</a>
            </p>
        </div>
    );
};

export default Contact;

import { FC, HTMLAttributes } from 'react';
import './block.scss';

const Block: FC<HTMLAttributes<HTMLElement>> = ({ children, className, ...props }) => {

    return (
        <div className="block">
            <div className="block-content">
                {children}
            </div>
        </div>
    );
};

export default Block;
